import { TI18nKey } from 'components/i18n/TranslatedText/i18nKey';
import INavigation, { INavigationItem, INavigationParent, NAVIGATION_TYPE } from 'models/INavigation';
import React, { createContext, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPageId, getPageSlug } from 'services/app';
import { replace } from 'services/app-router';
import { dismissModal, showModal } from 'services/modals';
import { ModalKinds } from 'services/modals/types';
import { getDefaultNavigation, getOpenNavigation, toggleNavigation, updateNavigation } from 'services/navigationv2';
import { constructPathForSlug } from 'services/navigationv2/utils';

interface PageNavigationContextState {
  closeNavigation: () => void;
  isOpen: boolean;
  onDragEnd: (args: { dragItem: INavigationItem; items: INavigationParent[] }) => void;
  openNavigation: () => void;
  searchText: string;
  setPageNavigationSearch: (value: string) => void;
}

export const PageNavigationContext = createContext({} as PageNavigationContextState);

const PageNavigationContextProvider = ({ children }: { children: React.ReactNode }) => {
  const openNavigationV1 = useSelector(getOpenNavigation);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const navigation = useSelector(getDefaultNavigation);
  const currentPageId = useSelector(getPageId);
  const currentPageSlug = useSelector(getPageSlug);

  React.useEffect(() => {
    setIsOpen(openNavigationV1);
  }, [openNavigationV1]);

  const closeNavigation = () => {
    setSearchText('');
    setIsOpen(false);
    dispatch(toggleNavigation(false));
  };

  const openNavigation = () => {
    setSearchText('');
    setIsOpen(true);
    dispatch(toggleNavigation(true));
  };

  const onDragEnd = ({
    dragItem,
    items: updatedParents, // updated parents after drag and drop
  }: {
    dragItem: INavigationItem;
    items: INavigationParent[];
  }) => {
    const updatedNavigation: INavigation = {
      ...navigation,
      parents: updatedParents,
    };

    updatedNavigation.parents.forEach(parent => {
      parent.children.forEach(child => {
        // @ts-ignore
        delete child.children; // the library automatically adds a children property to the children. we don't want that.
      });
    });

    const isEqual = JSON.stringify(navigation.parents) === JSON.stringify(updatedNavigation.parents);
    if (isEqual) return;

    let isParentBeingDragged = false;
    let isChildBeingDragged = false;
    let originalParentId = '';
    for (const parent of navigation.parents) {
      if (parent.id === dragItem.id) {
        isParentBeingDragged = true;
      }

      for (const child of parent.children) {
        if (child.id === dragItem.id) {
          isChildBeingDragged = true;
          originalParentId = parent.id;
        }
      }
    }

    let isParentBecomingChild = false;
    let isChildBecomingParent = false;
    for (const updatedParent of updatedNavigation.parents) {
      if (updatedParent.children.some(child => child.id === dragItem.id)) {
        isParentBecomingChild = true;
      }

      if (updatedParent.id === dragItem.id) {
        isChildBecomingParent = true;
      }
    }
    const parentBecameAChild = isParentBeingDragged && isParentBecomingChild;
    const childBecameAParent = isChildBeingDragged && isChildBecomingParent;
    const childMovedToAnotherParent = originalParentId && !updatedNavigation.parents.some(parent => parent.id === originalParentId && parent.children.some(child => child.id === dragItem.id));

    if (parentBecameAChild || childBecameAParent || childMovedToAnotherParent) {
      const titleKey: TI18nKey = dragItem.type === 'landing' ? 'ADMIN_SITE_STRUCTURE_DRAG_AND_DROP_PAGE_CONFIRMATION_TITLE' : 'ADMIN_SITE_STRUCTURE_DRAG_AND_DROP_CHANNEL_CONFIRMATION_TITLE';
      const subtitleKey: TI18nKey = dragItem.type === 'landing' ? 'ADMIN_SITE_STRUCTURE_DRAG_AND_DROP_PAGE_CONFIRMATION_SUBTITLE' : 'ADMIN_SITE_STRUCTURE_DRAG_AND_DROP_CHANNEL_CONFIRMATION_SUBTITLE';
      const updatePath = currentPageId === dragItem.id && updatedNavigation.type === NAVIGATION_TYPE.default;
      return showConfirmationModal(updatedNavigation, titleKey, subtitleKey, updatePath);
    }

    dispatch(updateNavigation(updatedNavigation));
  };

  const showConfirmationModal = (updatedNavigation: INavigation, titleKey: TI18nKey, subtitleKey: TI18nKey, updatePath: boolean) => {
    dispatch(
      showModal({
        kind: ModalKinds.adminConfirmation,
        data: {
          onConfirmClick: () => {
            dispatch(updateNavigation(updatedNavigation));
            dispatch(dismissModal('adminConfirmation'));
            if (updatePath) updateActivePagePath(updatedNavigation);
          },
          titleKey,
          subtitleKey,
        },
      }),
    );
  };

  const updateActivePagePath = (updatedNavigation: INavigation) => {
    const path = constructPathForSlug(updatedNavigation, currentPageSlug);
    return dispatch(replace({ path }));
  };

  return (
    <PageNavigationContext.Provider
      value={{
        searchText,
        closeNavigation,
        openNavigation,
        setPageNavigationSearch: setSearchText,
        isOpen,
        onDragEnd,
      }}
    >
      {children}
    </PageNavigationContext.Provider>
  );
};
export default PageNavigationContextProvider;

export const usePageNavigationContext = () => {
  const context = useContext(PageNavigationContext);

  if (!context) {
    throw new Error('usePageNavigationContext must be used within a PageNavigationContextProvider');
  }

  return context;
};
